<template>
  <section name="competencia">
    <v-row>
      <v-col cols="12">
        <Crud
          title="Gestão de Competências"
          buttonAddItemText="Nova competência"
          searchLabelText="Pesquisar na lista"
          :searchTextField.sync="searchTextField"
          :formTitle="nameForm"
          :callBackModalCancelar="fechar"
          :dialog.sync="dialog"
          :callBackModalSalvar="salvar"
          :loadingButtonSave="loadings.loadingButtonSave"
          :dialogDelete.sync="dialogDelete"
        >
          <template slot="data-content">
            <v-row class="mt-5">
              <!-- FILTRO CAMPOS -->
            </v-row>
          </template>
          <template slot="data-table">
            <v-data-table
              :headers="
                headers.filter((header) => {
                  return header.value != 'editar' && header.value != 'acao';
                })
              "
              :items="competencias"
              :loading="loadings.loadingTable"
            >
              <!-- ACTIONS -->
              <template v-slot:[`item.actions`]="{ item }">
                <v-menu bottom left>
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn icon v-bind="attrs" v-on="on">
                      <v-icon>mdi-dots-vertical</v-icon>
                    </v-btn>
                  </template>

                  <v-list>
                    <!-- Visualizar -->
                    <v-list-item>
                      <v-list-item-title>
                        <v-tooltip bottom>
                          <template v-slot:activator="{ on, attrs }">
                            <v-btn icon v-bind="attrs" v-on="on">
                              <v-icon
                                class="actions"
                                @click="toggleModalLateral(item)"
                              >
                                mdi-eye
                              </v-icon>
                            </v-btn>
                          </template>
                          <span>Visualizar</span>
                        </v-tooltip>
                      </v-list-item-title>
                    </v-list-item>

                    <!-- Editar -->
                    <v-list-item>
                      <v-list-item-title>
                        <v-tooltip bottom>
                          <template v-slot:activator="{ on, attrs }">
                            <v-btn icon v-bind="attrs" v-on="on">
                              <v-icon class="actions" @click="editar(item)">
                                mdi-pencil
                              </v-icon>
                            </v-btn>
                          </template>
                          <span>Editar</span>
                        </v-tooltip>
                      </v-list-item-title>
                    </v-list-item>

                    <!-- Delete -->
                    <v-list-item>
                      <v-list-item-title>
                        <v-tooltip bottom>
                          <template v-slot:activator="{ on, attrs }">
                            <v-btn icon v-bind="attrs" v-on="on">
                              <v-icon
                                class="actions"
                                @click="remover(item, true)"
                              >
                                mdi-delete
                              </v-icon>
                            </v-btn>
                          </template>
                          <span>Deletar</span>
                        </v-tooltip>
                      </v-list-item-title>
                    </v-list-item>
                  </v-list>
                </v-menu>
              </template>
              <template v-slot:[`item.nivel`]="{ item }">
                <span v-if="item.nivel && item.nivel.descricao">{{
                  item.nivel.descricao
                }}</span>
                <v-chip v-else>Não tem nível</v-chip>
              </template>
              <template v-slot:[`item.peso`]="{ item }">
                <v-chip v-if="item.peso == null">Não tem peso</v-chip>
                <span v-else>{{ item.peso }}</span>
              </template>
              <template v-slot:[`item.created_at`]="{ item }">
                <span
                  >{{ item.created_at | dateFormatBr() }}
                  {{ item.created_at | timeFormatBr() }}h</span
                >
              </template>
              <template v-slot:[`item.updated_at`]="{ item }">
                <span
                  >{{ item.updated_at | dateFormatBr() }}
                  {{ item.updated_at | timeFormatBr() }}h</span
                >
              </template>
            </v-data-table>
          </template>
          <!--------------- MODAL DE DELETE --------------------------------------------- -->
          <template slot="modalDelete/titleModal">
            Excluir o registro?
          </template>
          <template slot="modalDelete/contentModal">
            <p>
              Atenção! Você está prestes a realizar uma ação que não pode ser
              desfeita.
            </p>
            <p>Você realmente deseja excluir esse registro?</p>
          </template>
          <template slot="modalDelete/actionsModal">
            <v-btn depressed @click="dialogDelete = false">Fechar</v-btn>
            <v-btn
              :loading="loadingButtonDelete"
              color="error"
              @click="remover()"
            >
              Excluir
            </v-btn>
            <v-spacer class="d-none d-md-block"></v-spacer>
          </template>
          <!-- ------------- MODAL DE CADASTRO --------------------------------------------- -->
          <template slot="contentModalCrud">
            <CompetenciaForm />
          </template>
        </Crud>
      </v-col>
    </v-row>

    <ModalLateral
      :show="modalLateral.show"
      :hide="toggleModalLateral"
      :title="modalLateral.title"
      :headers="headerModalLateral"
      :item="modalLateral.item"
    >
      <template v-slot:[`item.created_at`]="{ item }">
        <span
          >{{ item.created_at | dateFormatBr() }}
          {{ item.created_at | timeFormatBr() }}h</span
        >
      </template>
      <template v-slot:[`item.updated_at`]="{ item }">
        <span
          >{{ item.updated_at | dateFormatBr() }}
          {{ item.updated_at | timeFormatBr() }}h</span
        >
      </template>
      <template v-slot:[`item.nivel`]="{ item }">
        <span v-if="item.nivel && item.nivel.descricao">{{
          item.nivel.descricao
        }}</span>
        <v-chip v-else>Não tem nível</v-chip>
      </template>
      <template v-slot:[`item.peso`]="{ item }">
        <v-chip v-if="item.peso == null">Não tem peso</v-chip>
        <span v-else>{{ item.peso }}</span>
      </template>
      <template v-slot:[`item.cargos`]="{ item }">
        <div v-if="item.cargos && item.cargos.length">
          <v-chip-group>
            <v-chip v-for="cargo in item.cargos" :key="cargo.id">{{
              cargo.nome
            }}</v-chip>
          </v-chip-group>
        </div>
        <v-chip v-else>Não tem nenhum cargo cadastrado.</v-chip>
      </template>
      <template v-slot:[`item.setores`]="{ item }">
        <div v-if="item.setores && item.setores.length">
          <v-chip-group>
            <v-chip v-for="setor in item.setores" :key="setor.id">{{
              setor.nome
            }}</v-chip>
          </v-chip-group>
        </div>
        <v-chip v-else>Não tem nenhum setor cadastrado.</v-chip>
      </template>
    </ModalLateral>
  </section>
</template>

<script>
import Crud from "@/components/Crud.vue";
import ModalLateral from "@/components/ModalLateral.vue";
import CompetenciaForm from "./forms/CompetenciaForm.vue";

export default {
  name: "CompetenciasComponent",
  inject: ["$validator"],
  components: {
    Crud,
    ModalLateral,
    CompetenciaForm,
  },
  data() {
    return {
      nameForm: "Nova Competência",
      modalLateral: {
        show: false,
        item: [],
        title: "",
      },
      SelectItensStatus: [
        { text: "Ativo", value: "A" },
        { text: "Inativo", value: "I" },
      ],
      searchTextField: "",
      dialog: false,
      loadings: {
        loadingButtonSave: false,
        loadingTable: false,
      },
      headers: [
        { text: "Descrição", value: "descricao" },
        { text: "Peso", value: "peso" },
        { text: "Nível", value: "nivel" },
        { text: "Data de criação", value: "created_at" },
        { text: "Data de atualização", value: "updated_at" },
        { text: "Ações", align: "end", value: "actions", sortable: false },
      ],
      headerModalLateral: [
        { text: "Descrição", value: "descricao" },
        { text: "Peso", value: "peso" },
        { text: "Cargos", value: "cargos" },
        { text: "Setores", value: "setores" },
        { text: "Nível", value: "nivel" },
        { text: "Data de criação", value: "created_at" },
        { text: "Data de atualização", value: "updated_at" },
      ],

      // Modal delete
      dialogDelete: false,
      loadingButtonDelete: false,
      item: [],
    };
  },

  async created() {
    this.loadings.loadingTable = true;

    await this.$store.dispatch("avdCompetencias/initialize");

    this.loadings.loadingTable = false;
  },

  computed: {
    competencia: {
      get() {
        return this.$store.getters["avdCompetencias/getCompetencia"];
      },
      set(value) {
        this.$store.dispatch("avdCompetencias/setCompetencia", value);
      },
    },
    competencias: {
      get() {
        return this.$store.getters["avdCompetencias/getCompetencias"];
      },
    },
  },

  methods: {
    salvar() {
      this.$validator.validate("avd-competencia.*").then((result) => {
        if (result) {
          const isEdit = this.competencia.id != undefined ? 1 : -1;
          this.loadings.loadingButtonSave = true;

          let url =
            isEdit === -1
              ? "/avd-competencia"
              : `/avd-competencia/${this.competencia.uuid}`;
          let method = isEdit === -1 ? "POST" : "PUT";
          let sucesso =
            isEdit === -1
              ? "Competência cadastrado com sucesso."
              : "Competência atualizado com sucesso.";
          let erro =
            isEdit === -1
              ? "Erro ao salvar competência."
              : "Erro ao atualizar competência.";

          let competenciaItem = this.competencia;
          competenciaItem._method = method;
          competenciaItem.url = url;

          this.$store
            .dispatch("avdCompetencias/save", competenciaItem)
            .then((response) => {
              if (response != "NAO_AUTORIZADO") {
                if (isEdit === -1) {
                  this.competencias.push(response);
                } else {
                  const index = this.competencias.findIndex(
                    (el) => el.id == competenciaItem.id
                  );

                  if (competenciaItem.nivel) {
                    const niveis = this.$store.getters["avdNiveis/getNiveis"];
                    const newNivel = niveis.find(
                      (el) => el.id == competenciaItem.id_nivel
                    );

                    competenciaItem.nivel = newNivel;
                  }

                  Object.assign(this.competencias[index], competenciaItem);
                }

                this.$store.dispatch("snackbar/ativarSnackbarGlobal", {
                  visibilidade: true,
                  mensagem: sucesso,
                  cor: "green",
                });
              }
            })
            .catch(() => {
              this.$store.dispatch("snackbar/ativarSnackbarGlobal", {
                visibilidade: true,
                mensagem: erro,
                cor: "red",
              });
            })
            .finally(() => {
              this.loadings.loadingButtonSave = false;
              this.fechar();
            });
        }
      });
    },
    editar(item) {
      this.loadings.loadingTable = true;

      this.$store
        .dispatch("avdCompetencias/getAllData", item.uuid)
        .then((response) => {
          this.competencia = response;
          this.nameForm = "Editar competência: " + this.competencia.descricao;
          this.dialog = true;

          this.extractIds("cargos").extractIds("setores");
        })
        .catch(() => {
          this.$store.dispatch("snackbar/ativarSnackbarGlobal", {
            visibilidade: true,
            mensagem:
              "Houve um erro ao buscar todos os dados da competência selecionada.",
            cor: "red",
          });
        })
        .finally(() => {
          this.loadings.loadingTable = false;
        });
    },
    fechar() {
      this.dialog = false;
      this.nameForm = "Nova competência";
      this.competencia = {
        id_cliente: null,
        id_nivel: null,
        descricao: "",
        peso: null,
        ids_cargos: [],
        ids_setores: [],
        comportamentos: [
          {
            descricao: "",
          },
        ],
      };
    },
    remover(item, openModal = false) {
      if (openModal) {
        this.item = Object.assign({}, item);
        this.dialogDelete = true;
      } else {
        this.loadingButtonDelete = true;
        const indexItem = this.competencias.findIndex(
          (el) => el.id == this.item.id
        );

        this.$store
          .dispatch("avdCompetencias/remove", this.item.uuid)
          .then((response) => {
            if (response === true) {
              this.competencias.splice(indexItem, 1);

              this.$store.dispatch("snackBarModule/openSnackBar", {
                color: "success",
                timeout: 3000,
                text: "Competência removida com sucesso.",
              });
            }
          })
          .catch(() => {
            this.$store.dispatch("snackBarModule/openSnackBar", {
              color: "error",
              timeout: 3000,
              text: "Não foi possível remover a competência. Por favor, tente novamente mais tarde!",
            });
          })
          .finally(() => {
            this.loadingButtonDelete = false;
            this.dialogDelete = false;
            this.item = [];
          });
      }
    },
    toggleModalLateral(item) {
      if (!this.modalLateral.show) {
        this.loadings.loadingTable = true;

        this.$store
          .dispatch("avdCompetencias/getAllData", item.uuid)
          .then((response) => {
            this.modalLateral.title = response.nome;
            this.modalLateral.item = item ? response : [];
            this.modalLateral.show = true;
          })
          .catch(() => {
            this.$store.dispatch("snackbar/ativarSnackbarGlobal", {
              visibilidade: true,
              mensagem:
                "Houve um erro ao buscar todos os dados da competência selecionada.",
              cor: "red",
            });
          })
          .finally(() => {
            this.loadings.loadingTable = false;
          });
      } else {
        this.modalLateral.title = "";
        this.modalLateral.item = [];
        this.modalLateral.show = false;
      }
    },
    /**
     * @description Cria propriedade do tipo array com os ids coletados a partir de outro array escolhido.
     *
     * @param key - Chave do array que deseja pegar os id e também gerar outra propriedade array.
     * @example - `cargos: [{id: 1 ...}, {id: 2 ...}]` => `ids_cargos: [1, 2]`
     *
     * @author Matheus Eduardo França <matheusefranca1727@gmail.com>
     */
    extractIds(key = String) {
      if (
        this.competencia &&
        this.competencia[key] &&
        this.competencia[key].length > 0
      ) {
        let arrayIds = [];

        this.competencia[key].forEach((el) => {
          const id = parseInt(el.id);
          arrayIds.push(id);
        });

        this.$set(this.competencia, `ids_${key}`, arrayIds);
      } else {
        this.$set(this.competencia, `ids_${key}`, []);
      }

      return this;
    },
  },
};
</script>

<style scoped></style>
